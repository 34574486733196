import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  .none {
    display: none;
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  .buttonBuscar {
    transition: filter 1s;
  }
  .buttonBuscar:hover {
    filter: brightness(0.8);
  }
  .addMargin {
    margin-top: 40px;
  }

  .elements {
    padding: 0px;
  }

  .confirm {
    width: 100% !important;
  }
  .bt-confirm {
    background: red !important;
  }

  .super-app-theme--header {
    background-color: #8850bf !important;
    color: #fff;
  }
  .super-app-theme--cell {
    text-align: center !important;
    align-items: center !important;
  }
  .table-input {
    th {
      text-align: right;
      font-weight: 400;
    }

    .css-13fq05f {
      border: none;
      border-right: 1px solid #ccc;
      border-radius: 0px;
    }
  }
  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  .containerBusca {
    @media (max-width: 990px) {
      width: 100% !important;
      padding-right: 0px !important;
    }
  }

  .containerBusca #containe-busca-produto > div:first-child {
    width: 83% !important;
    @media (max-width: 1200px) {
      width: 75% !important;
    }
    @media (max-width: 990px) {
      width: 100% !important;
    }
  }

  .containerBusca #containe-busca-produto .containerButton {
    @media (max-width: 990px) {
      width: 100% !important;
    }
  }

  .containerBusca #containe-busca-produto .containerButton .confirm {
    @media (max-width: 990px) {
      width: 50% !important;
    }
  }

  .containerCancelar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonCancel = styled.button`
  height: 40px;
  min-width: 150px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  margin: 0 5px;
  background-color: #dc3545;
  margin-right: 9px;
  &:disabled {
    background-color: #e3bec1;
    cursor: not-allowed;
    &:hover {
      background-color: #e3bec1;
      opacity: 1;
    }
    // eslint-disable-next-line prettier/prettier
  }
  &:hover {
    opacity: 0.7;
  }
  box-shadow: transparent !important;

  @media (max-width: 990px) {
    width: 50% !important;
  }
`;
