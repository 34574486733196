import * as yup from 'yup';

export interface ITara {
  cod_tara: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_tara: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  val_tara: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
}
export interface ProdutoDtails {
  plu: number;
  descricao: string;
  dta_alteracao: Date;
}
export interface ProdutoGride {
  cod_loja: number;
  des_loja: string;
  des_cidade: string;
  cod_produto: number;
  des_produto: string;
  dta_alteracao: Date;
  val_pauta_ipv: number | string;
  val_pauta_iva: number | string;
}

export const schemaPauta = yup
  .object({
    produtos: yup.object().shape({
      cod_produto: yup.string().required(),
      des_produto: yup.number().required(),
    }),
  })
  .required();
