import Spinner from '@atlaskit/spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import FormDefault from '~/components/FormDefault';
import InputMoney from '~/components/Inputs/InputMoney';
import { BuscaProduto } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import api from '~/services/api';
import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ButtonCancel, Container } from './styles';
import { ProdutoDtails, ProdutoGride, schemaPauta } from './types';
import { formatCurrency } from './utils/formatCurrency';
import { LojaContext } from '~/context/loja';
import { gridSemDuplicatas } from './utils/gridSemDuplicatas';

const CadastroPauta: React.FC = () => {
  const { loja } = useContext(LojaContext);

  const [loader, setLoader] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [invalidInputIPV, setInvalidInputIPV] = useState(false);
  const [invalidInputIVA, setInvalidInputIVA] = useState(false);
  const [codProduto, setCodProduto] = useState<string | undefined>(undefined);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [clearField, setClearField] = useState(false);
  const [produtoDetails, setProdutoDetails] = useState<
    ProdutoDtails | undefined
  >(undefined);
  const [produtosGride, setProdutosGride] = useState<
    Array<ProdutoGride> | undefined
  >(undefined);

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schemaPauta),
  });

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setValue('produtos', {
      value: '',
      label: '',
    });
    setCodProduto(undefined);
    setProdutoDetails(undefined);
    setIsDisableSearch(false);
    setValue('produtos_gride', undefined);
    setProdutosGride(undefined);
    setInitInicializado(false);
    setClearField(true);
    reset();
  }, [reset, setValue]);

  const handleClear = useCallback(() => {
    setValue('produtos', {
      value: '',
      label: '',
    });
    setIsDisableSearch(false);
    setCodProduto(undefined);
    setProdutoDetails(undefined);
    setClearField(true);
    setValue('produtos_gride', []);
    setProdutosGride([]);
    reset();
  }, [reset, setValue]);

  const getProdutos = useCallback(
    (e: any) => {
      setClearField(false);
      setIsDisableSearch(true);
      setCodProduto(String(e[0].cod_produto));
      setValue('cod_produto', String(e[0].cod_produto));

      const grid = e
        .filter((it: any) => it.flg_loja_inativa !== true)
        .map((item: any) => ({
          key: nanoid(),
          cod_loja: item.cod_loja,
          des_loja: item.des_loja,
          des_cidade: item.des_cidade,
          cod_produto: item.cod_produto,
          des_produto: item.des_produto,
          dta_alteracao: item.dta_alteracao,
          val_pauta_ipv: formatCurrency(item.val_pauta_ipv),
          val_pauta_iva: formatCurrency(item.val_pauta_iva),
        }));

      if (grid?.length <= 0) {
        toast.warning(
          'Produto não foi encontrado, pois a loja a qual pertence está inativa',
        );
        handleClear();
        return;
      }

      setValue('produtos_gride', gridSemDuplicatas(grid));
      setProdutosGride(gridSemDuplicatas(grid));
    },
    [handleClear, setValue],
  );

  useEffect(() => {
    if (produtosGride) {
      if (produtosGride.length > 0) {
        setProdutoDetails({
          plu: produtosGride[0].cod_produto,
          descricao: produtosGride[0].des_produto,
          dta_alteracao: produtosGride[0].dta_alteracao,
        });
      }
    }
    setClearField(false);
  }, [produtosGride]);

  /**
   * Validações
   */

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_produto } = row;

    setCodProduto(cod_produto);
    const { data } = await api.get(`/pauta/${cod_produto}`);
    if (data.data.length > 0) {
      const grid = [];
      for (let index = 0; index < data.data.length; index++) {
        grid.push({
          cod_loja: data.data[index].cod_loja,
          des_loja: data.data[index].des_loja,
          des_cidade: data.data[index].des_cidade,
          cod_produto: data.data[index].cod_produto,
          des_produto: data.data[index].des_produto,
          dta_alteracao: data.data[index].dta_alteracao,
          val_pauta_ipv: parseFloat(data.data[index].val_pauta_ipv)
            .toFixed(2)
            .replace('.', ','),
          val_pauta_iva: parseFloat(data.data[index].val_pauta_iva)
            .toFixed(2)
            .replace('.', ','),
        });
      }
      setValue('produtos_gride', grid);
      setValue('cod_produto', String(cod_produto));
      setProdutosGride(grid);
    } else {
      setValue('cod_produto', undefined);
      setCodProduto(undefined);
      setProdutoDetails(undefined);
      setValue('produtos_gride', undefined);
      setProdutosGride(undefined);
      toast.warning('Não foi localizado nenhum produto com esse código');
    }

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const handleSubmit = useCallback(async () => {
    try {
      const { produtos_gride, cod_produto } = getValues();
      setInitInicializado(true);

      if (produtos_gride) {
        if (invalidInputIPV !== true && invalidInputIVA !== true) {
          if (produtos_gride.length < 1) {
            toast.warning('Nenhum produdo selecionado ');
            return;
          }

          const formatProduto = [];
          for (let index = 0; index < produtos_gride.length; index++) {
            formatProduto.push({
              cod_loja: produtos_gride[index].cod_loja,
              val_pauta_ipv: String(
                produtos_gride[index].val_pauta_ipv,
              ).replace(',', '.'),
              val_pauta_iva: String(
                produtos_gride[index].val_pauta_iva,
              ).replace(',', '.'),
            });
          }

          const { data } = await api.put(
            `/pauta/${cod_produto}`,
            formatProduto,
          );
          if (data.success) {
            toast.success(data.message);
            resetFormData();
            setShowSearch(true);
            setUpdate(false);
          } else {
            toast.warn(data.message);
            setLoader(false);
          }
        }
      } else {
        toast.warning('Nenhum produdo selecionado ');
        return;
      }
    } finally {
      setLoader(false);
      setInitInicializado(false);
    }
  }, [invalidInputIPV, invalidInputIVA, resetFormData, getValues]);

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={127}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={127}
          title="Cadastro de Pauta"
          codigoRegistro={[
            {
              value: codProduto,
              des_campo: 'Código',
            },
          ]}
          onSave={handleSubmit}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => {
            alert;
          }}
          isDelete={false}
          onClearFields={() => {
            resetFormData();
          }}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div
              className={`mb-2 col-sm-12 col-md-12 containerBusca ${
                isUpdate ? 'none' : ''
              }`}
            >
              {!isUpdate && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <BuscaProduto
                      label="Buscar Produto"
                      placeholder="Selecione..."
                      name="produtos"
                      register={register}
                      isError={!!errors.produtos}
                      disabled={isDisableSearch}
                      control={control}
                      buscaNasLojas={[loja.cod_loja]}
                      customOptions={{
                        hideBuscarPor: true,
                        buscarPor: ['Produto'],
                      }}
                      getProduct={(selected: any) => {
                        setValue('produtos', {
                          value: selected[0].cod_produto,
                          label: selected[0].des_produto,
                        });
                        getProdutos(selected);
                      }}
                      style={{ width: '100%' }}
                      setValue={setValue}
                    />
                  </div>
                  <div style={{ marginTop: '1.875rem' }}>
                    <ButtonCancel
                      type="button"
                      disabled={!isDisableSearch}
                      onClick={() => handleClear()}
                    >
                      Cancelar
                    </ButtonCancel>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`col-sm-12 col-md-12 ${isUpdate ? 'addMargin' : ''}`}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>PLU</th>
                    <th>Descrição</th>
                    <th>Data da Última Alteração</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: '37px' }}>
                    <td>{produtoDetails?.plu}</td>
                    <td>{produtoDetails?.descricao}</td>
                    <td>{produtoDetails?.dta_alteracao}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 col-md-12 ">
              <Separator
                labelText="R$ Valores por Loja"
                color="#6B778c"
                marginTop="5px"
                css={{
                  fontWeight: 'bold',
                  paddingLeft: '0px',
                  marginLeft: '0px',
                  marginTop: '0px',
                  fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    'Segoe UI',
                    'Roboto',
                    'Oxygen',
                    'Ubuntu',
                    'Fira Sans',
                    'Droid Sans',
                    'Helvetica',
                    'Neue',
                    'sans-serif',
                  ],
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12" style={{ minHeight: '220px' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="col-sm-4 col-md-6">Loja</TableCell>
                      <TableCell className="col-sm-4 col-md-3 col-lg-2">
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          color="#fff"
                          style={{
                            maxWidth: '24px',
                            height: '24px',
                            color: '#fff',
                          }}
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>{' '}
                        Valor Pauta IPV
                      </TableCell>
                      <TableCell className="col-sm-4 col-md-3 col-lg-2">
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          style={{
                            maxWidth: '24px',
                            height: '24px',
                            color: '#fff',
                          }}
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>{' '}
                        Valor Pauta IVA (ST)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {produtosGride
                      ? produtosGride
                          .sort(function (a, b) {
                            if (a.cod_loja > b.cod_loja) {
                              return 1;
                            }
                            if (a.cod_loja < b.cod_loja) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((linha: any, index: number) => {
                            return (
                              <TableRow key={linha.key}>
                                <TableCell>
                                  {`${linha.cod_loja < 9 ? '0' : ''}${
                                    linha.cod_loja
                                  } - ${linha.des_loja} (${linha.des_cidade})`}
                                </TableCell>
                                <TableCell>
                                  <div
                                    style={{ marginLeft: '20px' }}
                                    className="col-sm-12 col-md-11"
                                  >
                                    <InputMoney
                                      label=""
                                      value={linha.val_pauta_ipv}
                                      placeholder=""
                                      max={999.99}
                                      min={0.0}
                                      isRequired
                                      setInvalid={
                                        linha.val_pauta_ipv <= 0 ||
                                        linha.val_pauta_ipv === '' ||
                                        linha.val_pauta_ipv === undefined ||
                                        linha.val_pauta_ipv === null
                                      }
                                      iniInicializado={iniInicializado}
                                      onChange={(value) => {
                                        if (
                                          Number(value) < 0 ||
                                          value.length > 6 ||
                                          value === '' ||
                                          value === undefined ||
                                          value === null ||
                                          value.includes('-')
                                        ) {
                                          setInvalidInputIPV(true);
                                        } else {
                                          const prd = produtosGride.filter(
                                            (lg) => {
                                              return (
                                                lg.cod_loja !== linha.cod_loja
                                              );
                                            },
                                          );

                                          setValue('produtos_gride', [
                                            ...prd,
                                            {
                                              cod_loja:
                                                produtosGride[index].cod_loja,
                                              des_loja:
                                                produtosGride[index].des_loja,
                                              des_cidade:
                                                produtosGride[index].des_cidade,
                                              cod_produto:
                                                produtosGride[index]
                                                  .cod_produto,
                                              des_produto:
                                                produtosGride[index]
                                                  .des_produto,
                                              dta_alteracao:
                                                produtosGride[index]
                                                  .dta_alteracao,
                                              val_pauta_iva:
                                                produtosGride[index]
                                                  .val_pauta_iva,
                                              val_pauta_ipv: value,
                                            },
                                          ]);
                                          setProdutosGride([
                                            ...prd,
                                            {
                                              cod_loja:
                                                produtosGride[index].cod_loja,
                                              des_loja:
                                                produtosGride[index].des_loja,
                                              des_cidade:
                                                produtosGride[index].des_cidade,
                                              cod_produto:
                                                produtosGride[index]
                                                  .cod_produto,
                                              des_produto:
                                                produtosGride[index]
                                                  .des_produto,
                                              dta_alteracao:
                                                produtosGride[index]
                                                  .dta_alteracao,
                                              val_pauta_iva:
                                                produtosGride[index]
                                                  .val_pauta_iva,
                                              val_pauta_ipv: value,
                                            },
                                          ]);
                                          setInvalidInputIPV(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div
                                    style={{ marginLeft: '20px' }}
                                    className="col-sm-12 col-md-11"
                                  >
                                    <InputMoney
                                      label=""
                                      value={linha.val_pauta_iva}
                                      placeholder=""
                                      min={0.0}
                                      max={999.99}
                                      isRequired
                                      setInvalid={
                                        linha.val_pauta_iva <= 0 ||
                                        linha.val_pauta_iva === '' ||
                                        linha.val_pauta_iva === undefined ||
                                        linha.val_pauta_iva === null
                                      }
                                      iniInicializado={iniInicializado}
                                      onChange={(value) => {
                                        if (
                                          Number(value) < 0 ||
                                          value.length > 6 ||
                                          value === '' ||
                                          value === undefined ||
                                          value === null ||
                                          value.includes('-')
                                        ) {
                                          setInvalidInputIVA(true);
                                        } else {
                                          const prd = produtosGride.filter(
                                            (lg) => {
                                              return (
                                                lg.cod_loja !== linha.cod_loja
                                              );
                                            },
                                          );
                                          setValue('produtos_gride', [
                                            ...prd,
                                            {
                                              cod_loja:
                                                produtosGride[index].cod_loja,
                                              des_loja:
                                                produtosGride[index].des_loja,
                                              des_cidade:
                                                produtosGride[index].des_cidade,
                                              cod_produto:
                                                produtosGride[index]
                                                  .cod_produto,
                                              des_produto:
                                                produtosGride[index]
                                                  .des_produto,
                                              dta_alteracao:
                                                produtosGride[index]
                                                  .dta_alteracao,
                                              val_pauta_iva: value,
                                              val_pauta_ipv:
                                                produtosGride[index]
                                                  .val_pauta_ipv,
                                            },
                                          ]);
                                          setProdutosGride([
                                            ...prd,
                                            {
                                              cod_loja:
                                                produtosGride[index].cod_loja,
                                              des_loja:
                                                produtosGride[index].des_loja,
                                              des_cidade:
                                                produtosGride[index].des_cidade,
                                              cod_produto:
                                                produtosGride[index]
                                                  .cod_produto,
                                              des_produto:
                                                produtosGride[index]
                                                  .des_produto,
                                              dta_alteracao:
                                                produtosGride[index]
                                                  .dta_alteracao,
                                              val_pauta_iva: value,
                                              val_pauta_ipv:
                                                produtosGride[index]
                                                  .val_pauta_ipv,
                                            },
                                          ]);
                                          setInvalidInputIVA(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroPauta;
