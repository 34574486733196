export const gridSemDuplicatas = (grid: any): any =>
  grid.filter(
    (item: any, index: number, self: any) =>
      index ===
      self.findIndex(
        (t: any) =>
          t.cod_loja === item.cod_loja &&
          t.des_loja === item.des_loja &&
          t.des_cidade === item.des_cidade &&
          t.cod_produto === item.cod_produto &&
          t.des_produto === item.des_produto &&
          t.dta_alteracao === item.dta_alteracao &&
          t.val_pauta_ipv === item.val_pauta_ipv &&
          t.val_pauta_iva === item.val_pauta_iva,
      ),
  );
